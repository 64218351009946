//     extra parameters     //
//     ----------------     //
//  -> first_question       //
//  -> final_question       //
//  -> specialcss           //
//  -> multiple             //
//////////////////////////////

export const QandA_OV50_CHBQS_V2_5 = [
  {
    id: "1",
    question: [`Hi!`,
      `Before we get started, please confirm you were born before 1974?`],
    first_question: true,
    options: [
      { id: "1", text: "Yes" },
      { id: "2", text: "No" },
    ],
  },
  {
    id: "5",
    question: [`Excellent, thank you.`,
      `Are you looking for coverage for just you, or you and your partner?`],
    specialcss: "verticleButton",
    options: [
      { id: "11", text: "Me" },
      { id: "12", text: "Me & My Partner" },
    ],
  },
  {
    id: "6",
    question: [
      `Okay, great`,
      `To help find you the best quote/policy possible, please tell us whats important to you when considering coverage options?`,
    ],
    specialcss: "verticleButton",
    multiple: true,
    options: [
      { id: "13", text: "Cover end of life expenses such as funeral costs" },
      { id: "14", text: "Leave financial security for my family" },
      { id: "15", text: "Leave money for an organization, such as a personal business or charity" },
      { id: "16", text: "Other" },
    ],
  },
  {
    id: "7",
    question: [
      `Congratulations! 🤩🤩🥳🥳🥳`,
      `Tap the button below to speak with one of our licensed agents.`,
      `This will guarantee you the lowest possible price and help you qualify, it will only take you 2 to 3 minutes.`,
    ],
    options: [
      { id: "17", text: "Call Now", specialcss: "CALL_CLICK", },

    ],
  },
  {
    id: "basic_info",
    question: [
      `Thank you for taking the time.`,
      `Please provide some of your details so our agent can assist you with this claim.`,
      `Help us with your Name`,
    ],
    specialcss: "form1",
    // final_question: true,
    options: [
      {},
    ],
  },
  {
    id: "phone_number",
    question: [
      `Thank you for taking the time.`,
      `Kindly provide your current phone number and Email to ensure effective communication.`,
    ],
    specialcss: "form2",
    // final_question: true,
    options: [
      {},
    ],
  },
  {
    id: "email",
    question: [
      `Thank you for that`,
      `Please provide your email address.`,
    ],
    specialcss: "form3",
    // final_question: true,
    options: [
      {},
    ],
  },
];

export const QandA_OV50_CHBQS_V2_5B = [
    {
      id: "1",
      question: [`Hi!`,
        `Before we get started, please confirm you were born before 1974?`],
      first_question: true,
      options: [
        { id: "1", text: "Yes" },
        { id: "2", text: "No" },
      ],
    },
    {
      id: "5",
      question: [`Excellent, thank you.`,
        `Are you looking for coverage for just you, or you and your partner?`],
      specialcss: "verticleButton",
      options: [
        { id: "11", text: "Me" },
        { id: "12", text: "Me & My Partner" },
      ],
    },
    {
      id: "6",
      question: [
        `Okay, great`,
        `To help find you the best quote/policy possible, please tell us whats important to you when considering coverage options?`,
      ],
      specialcss: "verticleButton",
      multiple: true,
      options: [
        { id: "13", text: "Cover end of life expenses such as funeral costs" },
        { id: "14", text: "Leave financial security for my family" },
        { id: "15", text: "Leave money for an organization, such as a personal business or charity" },
        { id: "16", text: "Other" },
      ],
    },
    {
      id: "7",
      question: [
        `Congratulations! 🤩🤩🥳🥳🥳`,
        `You could qualify for up to £35,000 in cover for as low as £1.25 a week!!`,
        `To get the best possible price available, speak to one of our licensed agents.`,
        `This streamlines the process and only takes 2 to 3 minutes.`,
      ],
      options: [
        { id: "17", text: "Call Now", specialcss: "CALL_CLICK", },

      ],
    },
];

export const QandA_OV50_CHBQS_V2_7_1B = [{
  id: "1",
  question: [`Hi!`,
    `Before we get started, please confirm you were born before 1974?`],
  first_question: true,
  options: [
    { id: "1", text: "Yes" },
    { id: "2", text: "No" },
  ],
},
{
  id: "5",
  question: [`Excellent, thank you.`,
    `Are you looking for coverage for just you, or you and your partner?`],
  specialcss: "verticleButton",
  options: [
    { id: "11", text: "Me" },
    { id: "12", text: "Me & My Partner" },
  ],
},
{
  id: "6",
  question: [
    `Okay, great`,
    `To help find you the best quote/policy possible, please tell us whats important to you when considering coverage options?`,
  ],
  specialcss: "verticleButton",
  multiple: true,
  options: [
    { id: "13", text: "Cover end of life expenses such as funeral costs" },
    { id: "14", text: "Leave financial security for my family" },
    { id: "15", text: "Leave money for an organization, such as a personal business or charity" },
    { id: "16", text: "Other" },
  ],
},
{
  id: "7",
  question: [
    `Congratulations! 🤩🤩🥳🥳🥳`,
    `You could qualify for up to £35,000 in cover for as low as £1.25 a week!!`,
    `To get the best possible price available, speak to one of our licensed agents.`,
    `This streamlines the process and only takes 2 to 3 minutes.`,
  ],
  options: [
    { id: "17", text: "Call Now", specialcss: "CALL_CLICK", },

  ],
},
{
  id: "basic_info",
  question: [
    `Thank you for taking the time.`,
    `Please provide some of your details so our agent can assist you with this claim.`,
    `Help us with your Name`,
  ],
  specialcss: "form1",
  // final_question: true,
  options: [
    {},
  ],
},
{
  id: "phone_number",
  question: [
    `Thank you for taking the time.`,
    `Kindly provide your current phone number and Email to ensure effective communication.`,
  ],
  specialcss: "form2",
  // final_question: true,
  options: [
    {},
  ],
},
{
  id: "email",
  question: [
    `Thank you for that`,
    `Please provide your email address.`,
  ],
  specialcss: "form3",
  // final_question: true,
  options: [
    {},
  ],
},
]

export const QandA_Optimize2convert = [{
  id: "1",
  question: [`Hi!`,
    `Before we get started, please confirm you were born before 1974?`],
  first_question: true,
  options: [
    { id: "1", text: "Yes" },
    { id: "2", text: "No" },
  ],
},
{
  id: "5",
  question: [`Excellent, thank you.`,
    `Are you looking for coverage for just you, or you and your partner?`],
  specialcss: "verticleButton",
  options: [
    { id: "11", text: "Me" },
    { id: "12", text: "Me & My Partner" },
  ],
},
{
  id: "6",
  question: [
    `Okay, great`,
    `To help find you the best quote/policy possible, please tell us whats important to you when considering coverage options?`,
  ],
  specialcss: "verticleButton",
  multiple: true,
  options: [
    { id: "13", text: "Cover end of life expenses such as funeral costs" },
    { id: "14", text: "Leave financial security for my family" },
    { id: "15", text: "Leave money for an organization, such as a personal business or charity" },
    { id: "16", text: "Other" },
  ],
},
{
  id: "7",
  question: [
    `Congratulations! 🤩🤩🥳🥳🥳`,
    `You could qualify for up to £35,000 in cover for as low as £1.25 a week!!`,
    `To get the best possible price available, speak to one of our licensed agents.`,
    `This streamlines the process and only takes 2 to 3 minutes.`,
  ],
  options: [
    { id: "17", text: "Call Now", specialcss: "CALL_CLICK", },

  ],
},
{
  id: "basic_info",
  question: [
    `Thank you for taking the time.`,
    `Please provide some of your details so our agent can assist you with this claim.`,
    `Help us with your Name`,
  ],
  specialcss: "form1",
  // final_question: true,
  options: [
    {},
  ],
},
{
  id: "phone_number",
  question: [
    `Thank you for taking the time.`,
    `Kindly provide your current phone number and Email to ensure effective communication.`,
  ],
  specialcss: "form2",
  // final_question: true,
  options: [
    {},
  ],
},
{
  id: "email",
  question: [
    `Thank you for that`,
    `Please provide your email address.`,
  ],
  specialcss: "form3",
  // final_question: true,
  options: [
    {},
  ],
},
]

export const QandA_OV50_CHBQS_V2_7_2B = [
  {
    id: "8",
    question: [`Get Life Insurance for less than £10 per month!`,
    `{li}Free, Instant Quote{li}Guaranteed Coverage (No Health Checks){li}Get Covered Immediately`,
      `Which of the following FREE BONUSES would you like to be included with your new Life Insurance policy?`],
      first_question: true,
      specialcss: "verticleButton",
      multiple: true,
      options: [
        { id: "19", text: " A Free Bespoke Will" },
        { id: "20", text: "Free Private GP Service 24/7 (avoid NHS queues)" },
        { id: "21", text: "Free Mental Health Cover" },
        { id: "22", text: " I’d Like All 3 of the Above" },
      ],
    },
  {
    id: "9",
    question: [`Are you between the ages of 50 and 80?`],
    options: [
      { id: "23", text: "YES" },
      { id: "24", text: "NO" },
    ],
  },

  {
    id: "10",
    question: [

      `Would you like your free quote to be on a policy that does NOT require a health check?`,
    ],
    options: [
      { id: "25", text: "YES" },
      { id: "26", text: "NO" },
    ],
  },
  {
    id: "7",
    question: [
      `Congratulations! 🤩🤩🥳🥳🥳 We are ready to offer you your free quote which will include the Free Bonuses you’ve selected above.` ,
      `We will keep the line open for 2 minutes for you to call our Instant Quote Hotline.  Tap the number below to call now for your Free, No-Obligation Quote:`,
    ],
    options: [
      { id: "17", text: "Call Now", specialcss: "CALL_CLICK", },

    ],
  },
  {
    id: "basic_info",
    question: [
      `Thank you for taking the time.`,
      `Please provide some of your details so our agent can assist you with this claim.`,
      `Help us with your Name`,
    ],
    specialcss: "form1",
    // final_question: true,
    options: [
      {},
    ],
  },
  {
    id: "phone_number",
    question: [
      `Thank you for taking the time.`,
      `Kindly provide your current phone number and Email to ensure effective communication.`,
    ],
    specialcss: "form2",
    // final_question: true,
    options: [
      {},
    ],
  },
  {
    id: "email",
    question: [
      `Thank you for that`,
      `Please provide your email address.`,
    ],
    specialcss: "form3",
    // final_question: true,
    options: [
      {},
    ],
  },
];

export const QandA_OV50_CHBQS_V2_8_2 = [
  {
    id: "1",
    question: [`Hi!`,
      `Before we get started, please confirm you were born before 1974?`],
    first_question: true,
    options: [
      { id: "1", text: "Yes" },
      { id: "2", text: "No" },
    ],
  },
  {
    id: "5",
    question: [`Excellent, thank you.`,
      `Are you looking for coverage for just you, or you and your partner?`],
    specialcss: "verticleButton",
    options: [
      { id: "11", text: "Me" },
      { id: "12", text: "Me & My Partner" },
    ],
  },
  {
    id: "6",
    question: [
      `Okay, great`,
      `To help find you the best quote/policy possible, please tell us whats important to you when considering coverage options?`,
    ],
    specialcss: "verticleButton",
    multiple: true,
    options: [
      { id: "13", text: "Cover end of life expenses such as funeral costs" },
      { id: "14", text: "Leave financial security for my family" },
      { id: "15", text: "Leave money for an organization, such as a personal business or charity" },
      { id: "16", text: "Other" },
    ],
  },
  {
    id: "7",
    question: [
      `Congratulations! 🤩🤩🥳🥳🥳`,
      `Please enter your phone number below to receive a call from one of our licensed agents.`,
      `This will guarantee you the lowest possible price and help you qualify, it will only take you 2 to 3 minutes.`,
    ],
    options: [
      { id: "17", text: "Submit", specialcss: "CALL_CLICK", },

    ],
  },
  {
    id: "basic_info",
    question: [
      `Thank you for taking the time.`,
      `Please provide some of your details so our agent can assist you with this claim.`,
      `Help us with your Name`,
    ],
    specialcss: "form1",
    // final_question: true,
    options: [
      {},
    ],
  },
  {
    id: "phone_number",
    question: [
      `Thank you for taking the time.`,
      `Kindly provide your current phone number and Email to ensure effective communication.`,
    ],
    specialcss: "form2",
    // final_question: true,
    options: [
      {},
    ],
  },
  {
    id: "email",
    question: [
      `Thank you for that`,
      `Please provide your email address.`,
    ],
    specialcss: "form3",
    // final_question: true,
    options: [
      {},
    ],
  },
];

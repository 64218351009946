import React, { useState, useContext, useEffect } from 'react'
import "../../../../src/assets/css/OV50_CHBQS_V2/chat.scss";

import { useDataIngestion } from "../../../Hooks/useDataIngestion";
import useJourneyStats from "../../../Hooks/useJourneyStats";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import VisitorParamsContext from "../../../Contexts/VisitorParams";
import { queryString } from "../../../Utility/QueryString";
import FbViewPixel from "../../../Utility/FbViewPixel";
import { useUserClicks } from '../../../Hooks/useUserClicks';
import { UUID } from "../../../Utility/UUID";
import ChatBotBubble_OV50_CHBQS_V2_1 from './ChatBotBubble_OV50_CHBQS_V2_1';
import Header from '../../Includes/Layouts/OV50_CHBQS_V2_1/Header_1';
import Footer from '../../Includes/Layouts/OV50_CHBQS_V2_1/Footer_1';
import RingbaApi from "../../../Utility/RingbaApi";

import OfflineSpanishButtonLink from "../../../Utility/OfflineSpanishButtonLink";
import RingbaPhoneNumber from "../../../Utility/RingbaPhoneNumber";

const OV50_CHBQS_V2_1 = () => {
  const campaign_name = "english";
  const splitName = 'OV50_CHBQS_V2_1';

  const { response } = RingbaApi(campaign_name);
  const query = new URLSearchParams(window.location.search);
  const utmName = query.get("utm_campaign");
  const [buttonRedirect, setButtonRedirect] = useState();
  // let is_online = response.is_online;
  let is_online = "online";
  let redirect = response.redirect_path;

  useEffect(() => {
    if (response) {
      const buttonofflineLink = OfflineSpanishButtonLink(redirect);
      setButtonRedirect(buttonofflineLink);
    }
  }, [response]);

  const { phoneNumber, formattedPhoneNumber } = RingbaPhoneNumber(utmName);

  const { saveDataIngestion, isLoading } = useDataIngestion();
  const saveJournyStats = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { fetchUuid } = UUID();
  const uuid = fetchUuid();

  const { saveUserClicks } = useUserClicks();
  const saveUser = async (name, status) => {
    await saveUserClicks(uuid, name, status);
  };

  const formSubmit = async (values) => {
    const queryString = '';
    values.lstSalutation = '';
    values.txtFName = '';
    values.txtLName = '';
    values.txtEmail = '';
    values.txtPhone = '';
    values.page_name = splitName;
    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      values,
      'split_form_submit',
      splitName,
      queryString,
      visitorParameters.data
    );
  };

  const journeyStatus = async (values) => {
    const { slide_type, slide_question, slide_value, type } = values;

    await saveJournyStats(uuid, slide_type, slide_question, slide_value, type, splitName);
  };
  return (
    <>
      <div className='OV50_CHBQS_V2'>
        <AdtopiaLoadLP PageType="LP" splitName={splitName} />
        <FbViewPixel />
        <div className="body-inner">
          <Header />
          <section className="chat-section">
            <div className="container">
              <div className=" mt-0 mb-lg-5 mb-md-5 mb-sm-3 mb-3">
                <div className="chat-wrap">
                  <div className="mb-1 support-online py-3 text-center">
                    <span className="circle pulse green me-1" /> Sarah is available
                  </div>
                  <div className="chat-box-inner">
                    <div className="row-chart-message mb-4 ">
                      <ChatBotBubble_OV50_CHBQS_V2_1
                        splitName={splitName}
                        formSubmit={formSubmit}
                        journeyStatus={journeyStatus}
                        saveUser={saveUser}
                        uuid={uuid}

                        is_online={is_online}
                        buttonRedirect={buttonRedirect}
                        phoneNumber={phoneNumber}
                        formattedPhoneNumber={formattedPhoneNumber}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default OV50_CHBQS_V2_1